import React from 'react';

function LatestFoodDelivery({ deliveryTime }) {
  return (
    <div
      className="flex flex-col w-11/12 mx-auto lg:w-full 
         bg-gray bg-opacity-50 border-2 border-transparent shadow-lg lg:shadow-md  p-2 rounded-xl lg:rounded-md"
    >
      <div class="flex lg:flex-row lg:items-center lg:justify-between flex-col items-start justify-evenly  ">
        <div>
          <span
            class="
               lg:text-sm
               text-xs
                font-semibold
                lg:tracking-wider
                uppercase
                inline-block
                py-1
                rounded-md
                text-white-bright           
                "
          >
            ⏱ Last food delivery
          </span>
        </div>
        <div class="text-left">
          <span class="lg:text-lg text-2xl font-bold inline-block text-white-bright">
            {deliveryTime}
          </span>
        </div>
      </div>
    </div>
  );
}
export default LatestFoodDelivery;
