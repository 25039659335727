/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from 'react';
import { useYopi } from '../contexts/yopi';
import ScheduleClock from '../components/Schedule';
import TimePicker from '../components/TimePicker';
import { el } from 'date-fns/locale';

export default function Example() {
  const { user, client, schedules, setSchedules } = useYopi();

  useEffect(() => {
    const getSchedules = async () => {
      const schedulesDb = await client
        .db('yopi_trial')
        .collection('config')
        .findOne({ serial_number: user.customData.serial_number });
      if (schedulesDb?.schedules) setSchedules(schedulesDb.schedules);
    };
    if (client && user) {
      getSchedules();
    }
  }, [client, user, setSchedules]);

  console.log('schedulesDb', schedules);

  return (
    <div
      className="bg-gray-black px-4 items-center flex flex-col "
      style={{ flex: 1, minHeight: '100vh' }}
    >
      <div className="flex mx-auto mt-4 justify-center items-center">
        <a href="/home">
          <p className="text-white-bright text-center">Back to main dashboard</p>
          <img className="lg:h-24 h-24 mt-4 w-auto" src="/yopilogo.png" alt="Home" />
        </a>
      </div>

      <div className="bg-gray-lightest m-4 p-4 h-full justify-center rounded-3xl ">
        <div className="flex mb-4 flex-col font-bold text-lg p-4 ">
          <p> Choose your desired feeding schedule and type </p>
        </div>

        <div className="row-span-2 space-between mt-4 items-center rounded-lg">
          {schedules
            ?.sort((a, b) => parseInt(a.time.replace(':', '')) - parseInt(b.time.replace(':', '')))
            .map((schedule, index) => (
              <ScheduleClock
                time={schedule.time}
                active={schedule.active}
                index={index}
              ></ScheduleClock>
            ))}
        </div>
        <div>
          <TimePicker> </TimePicker>
        </div>
      </div>
    </div>
  );
}
