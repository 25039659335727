import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyDeq6jDagjh7La9s3BQlEWXhLf2YP_D1Lw',
  authDomain: 'yopi-69c28.firebaseapp.com',
  projectId: 'yopi-69c28',
  storageBucket: 'yopi-69c28.appspot.com',
  messagingSenderId: '155310909698',
  appId: '1:155310909698:web:977605a3da293b5ba6ba1b',
  measurementId: 'G-06YW5VBS6G',
  databaseURL: 'https://yopi-69c28-default-rtdb.europe-west1.firebasedatabase.app/',
};

const app = initializeApp(firebaseConfig);
var database = getDatabase(app);

export { app, database };
