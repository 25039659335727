import React from 'react';

function Errors({ errorMsg }) {
  return (
    <div
      className="flex flex-col mx-auto w-11/12 lg:w-full 
         bg-pink-red bg-opacity-50 border-transparent shadow-lg lg:shadow-md font-light text-xs rounded-3xl p-2 lg:rounded-md"
    >
      <div class="flex lg:flex-row lg:items-center lg:justify-between flex-col justify-evenly ">
        <div class="flex lg:text-center">
          <span class="lg:text-3xs text-5xs whitespace-normal align-center font-bold inline-block text-white-bright">
            {errorMsg}
          </span>
        </div>
      </div>
    </div>
  );
}
export default Errors;
