import { React, useState } from 'react';
import { Switch, Button } from '@headlessui/react';

import { useYopi } from '../contexts/yopi';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ScheduleClock({ time, active, index }) {
  const { user, setUser, client, setClient, schedules, setSchedules } = useYopi();

  const updateOneSchedule = (index, active) => {
    const schedulesCopy = [...schedules];
    schedulesCopy[index] = {
      time: schedulesCopy[index]['time'],
      active,
    };
    setSchedules(schedulesCopy);
    client
      .db('yopi_trial')
      .collection('config')
      .updateOne(
        { serial_number: user.customData.serial_number },
        { $set: { schedules: schedulesCopy } },
        { upsert: true },
      );
  };
  const deleteOneSchedule = (index, active) => {
    const schedulesCopy = [...schedules];

    const removedItems = schedulesCopy.splice(index, 1);
    console.log(removedItems);
    client
      .db('yopi_trial')
      .collection('config')
      .updateOne(
        { serial_number: user.customData.serial_number },
        { $set: { schedules: schedulesCopy } },
        { upsert: true },
      );
    setSchedules(schedulesCopy);
  };
  return (
    <Switch.Group as="div" className="flex flex-row items-center justify-center space-x-4 ">
      <span className="flex-grow flex flex-col space-between">
        <Switch.Label as="span" className="text-xl font-semibold text-gray-darkest text-center" passive>
          {time}
        </Switch.Label>
      </span>
      <span className="inline-block font-thin text-xs whitespace-nowrap text-gray-darkest ">
        Dry Food
      </span>
      <Switch
        checked={active}
        onChange={value => updateOneSchedule(index, value)}
        className={classNames(
          active ? 'bg-gray-dark' : 'bg-gray-light',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            active ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 bg-yellow-bright rounded-full shadow transform ring-0 transition ease-in-out duration-200',
          )}
        ></span>
      </Switch>
      <span className="inline-block font-thin text-xs whitespace-nowrap text-gray-darkest">
        Wet Food
      </span>
      <Switch
        className=" bg-transparent shadow-sm w-8 h-8 rounded-lg font-bold text-2xl lg:ml-80 mt-2 ml-36 text-gray-dark relative "
        onChange={value => deleteOneSchedule(index, value)}
      >
        {' '}
        -{' '}
      </Switch>
    </Switch.Group>
  );
}

export default ScheduleClock;
