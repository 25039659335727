import React, { useEffect, useState } from 'react';

import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Paris');

function NextFoodDelivery({ deliveryTime }) {
  const [displayTime, setDisplayTime] = useState('--');

  useEffect(() => {
    if (deliveryTime) {
      const nextDel = moment()
        .hours(parseInt(deliveryTime.split(':')[0]))
        .minutes(parseInt(deliveryTime.split(':')[1]));
      setDisplayTime(
        nextDel.isAfter(moment().format('HH:mm')) ? 'no more deliveries' : nextDel.fromNow(),
      );
    } else {
      setDisplayTime('No more delivery planned today!')
    }
  }, [deliveryTime]);

  return (
    <div
      className="flex flex-col w-11/12 mx-auto lg:w-full
         bg-gray bg-opacity-50 border-2 border-transparent shadow-lg lg:shadow-md  p-2 rounded-xl lg:rounded-md"
    >
      <div className="flex lg:flex-row lg:items-center lg:justify-between flex-col items-start justify-evenly ">
        <div>
          <span
            className="
               lg:text-sm
               text-xs
                font-semibold
                lg:tracking-wider
                uppercase
                inline-block
                py-1
                rounded-md
                text-white-bright           
                "
          >
            ⏭ Next food delivery
          </span>
        </div>
        <div className="text-left">
          <span className="lg:text-lg text-2xl font-bold inline-block text-white-bright">
            {deliveryTime} ({displayTime})
          </span>
        </div>
      </div>
    </div>
  );
}
export default NextFoodDelivery;
