import React from 'react';
import ProgressBar from '../Other/ProgressBar';

function DryFoodProgress({ completed, label }) {
  return (
    <div
      className="flex flex-col w-11/12 mx-auto lg:w-full 
         bg-gray bg-opacity-50 border-2 border-transparent shadow-lg lg:shadow-md  p-2 rounded-xl lg:rounded-md"
    >
      <div class="flex lg:flex-row lg:items-center lg:justify-between flex-col items-start justify-evenly  ">
        <div style={{ flex: 1 }}>
          <span
            class="
               lg:text-sm
               text-xs
                font-semibold
                lg:tracking-wider
                uppercase
                inline-block
                py-1
                rounded-md
                text-white-bright           
                "
          >
            📊 Dry Food progress
          </span>
        </div>
        <div style={{ flex: 1, width: '100%' }}>
          <span class="lg:text-sm text-xl whitespace-nowrap font-bold text-white-bright">
            <ProgressBar completed={completed} label={label} />
          </span>
        </div>
      </div>
    </div>
  );
}
export default DryFoodProgress;
