import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { useYopi } from '../contexts/yopi';

function Logout() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { user, setUser } = useYopi();

  let navigate = useNavigate();

  const logOut = async () => {
    console.log(user);
    if (user) {
      setUser(await user.logOut());
      navigate('/login');
      toast.success('Successfully logged out!');
    }
  };

  return (
    <>
      <div className=" lg:mx-auto  w-full">
        <div
          className="lg:py-2 py-4 lg:px-0 px-0
          w-full rounded-lg "
        >
          <div className="space-y-6">
            <div>
              <button
                type="submit"
                onClick={e => logOut()}
                className="w-full flex justify-center py-2 px-8 bg-gray-black border 
                 rounded-md text-sm font-medium text-white-bright 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Logout;
