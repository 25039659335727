import React, { useContext, useEffect, useState } from 'react';
import { startOfDay } from 'date-fns';
import * as Realm from 'realm-web';

const YopiContext = React.createContext({});
export const useYopi = () => useContext(YopiContext);
export const YopiProvider = ({ children }) => {
  const [client, setClient] = useState(null);
  const [app, setApp] = useState(new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID }));

  const [user, setUser] = useState(app.currentUser || null);
  const [schedules, setSchedules] = useState([]);
  const [petName, setPetName] = useState('--');

  const [telemetry, setTelemetry] = useState([]);
  const [config, setConfig] = useState(null);
  const [aggData, setAggData] = useState(null);

  const [remainingFood, setRemainingFood] = useState('--');

  const syncConfigToDB = async obj => {
    return client.db('yopi_trial').collection('config').updateOne(
      {
        serial_number: user.customData.serial_number,
      },
      { $set: obj },
      { upsert: true },
    );
  };

  useEffect(() => {
    async function init() {
      if (user && !client) {
        setClient(app.currentUser.mongoClient('mongodb-atlas'));
      }
    }

    init();
  }, [app, client, user, schedules]);

  useEffect(() => {
    async function getData() {
      console.log('user', JSON.stringify(user.profile), user.customData);

      const targetMachine = user.customData.serial_number;

      console.log(targetMachine);
      const telemetryCollection = client.db('yopi_trial').collection('telemetry'); //calling the collection we need
      const telemetryDataQuery = telemetryCollection.find(
        {
          serial_number: targetMachine,
          timestamp: { $gte: startOfDay(new Date()) },
        },
        { sort: { timestamp: 1 } },
      );

      const configQuery = client.db('yopi_trial').collection('config').findOne({
        serial_number: targetMachine,
      });
      const aggregatedDataQuery = client
        .db('yopi_trial')
        .collection('daily_agg')
        .findOne(
          {
            timestamp: { $gte: startOfDay(new Date()) },
            serial_number: targetMachine,
          },
          { timestamp: 1 },
        );

      const [telemetryData, configs, aggregatedData] = await Promise.all([
        telemetryDataQuery,
        configQuery,
        aggregatedDataQuery,
      ]);

console.log(aggregatedData)

      setAggData(aggregatedData);
      setConfig(configs);
      
      setPetName(
        configs?.pet_settings.find(setting => Object.keys(setting).includes('pet_name'))['pet_name'] || "",
      );
      console.log('result', telemetryData);

      setTelemetry(telemetryData);
    }

    if (user && client) {
      getData();
    }
  }, [client, user, remainingFood]);

  return (
    <YopiContext.Provider
      value={{
        remainingFood,
        config,
        schedules,
        app,
        client,
        user,
        telemetry,
        petName,
        aggData,
        setRemainingFood,
        setAggData,
        setClient,
        setUser,
        setApp,
        setTelemetry,
        setSchedules,
        syncConfigToDB,
      }}
    >
      {children}
    </YopiContext.Provider>
  );
};
