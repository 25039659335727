/* eslint-disable jsx-a11y/anchor-is-valid */
import { React } from 'react';

import PhoneMenu from './PhoneMenu';
import MenuBar from './MenuBar';
// import { useYopi } from '../contexts/yopi';

// import { toast } from 'react-hot-toast';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Paris');

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

function AppHeader() {
  // const update_dry_food = async () => {
  //   const newTimestamp = moment().toDate();
  //   try {
  //     console.log('timestamp:', newTimestamp);
  //     syncConfigToDB({ push_dry_food: true, timestamp: newTimestamp });
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  // const update_wet_food = async () => {
  //   const newTimestamp = moment().toDate();
  //   try {
  //     console.log('timestamp:', newTimestamp);
  //     syncConfigToDB({ push_wet_food: true, timestamp: newTimestamp });
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  return (
    <header className="bg-gray-black border-transparent"     style={{ height: '15vh' }}>
      <nav className="relative max-w-7xl mx-auto sm:px-6 " aria-label="Top">
        <div className="w-full py-3 flex ">
          <div className="flex ml-4 items-center">
            <PhoneMenu className="flex align-top -mr-5" />
            <a href="login">
              <img className="w-auto" src="/yopilogo.png" alt="Home" style={{ height:'12vh' }} />
            </a>

            <div className="hidden lg:block ml-64 space-x-8 flex flex-col items-center justify-center">
              {/* <a
                href="/Schedule"
                className="inline-block bg-yellow-accent py-2 px-2 border border-transparent rounded-md text-base font-medium text-gray-black hover:bg-opacity-75"
              >
                Change Schedule
              </a>

              <button
                onClick={async () => {
                  toast.promise(update_dry_food(), {
                    loading: 'Pushing food...',
                    success: 'Command sent !',
                    error: 'Could not deliver food.',
                  });
                }}
                className="inline-block bg-yellow-lightest py-2 px-4 border border-transparent rounded-md text-base font-medium text-gray-dark hover:bg-opacity-50"
              >
                Deliver Dry Food
              </button>
              <button
                onClick={async () => {
                  toast.promise(update_wet_food(), {
                    loading: 'Pushing food...',
                    success: 'Command sent !',
                    error: 'Could not deliver food.',
                  });
                }}
                className="inline-block bg-yellow-lightest py-2 px-4 border border-transparent rounded-md text-base font-medium text-gray-dark hover:bg-opacity-50"
              >
                Deliver Wet Food
              </button> */}
              <div className="relative inline-block space-x-6 ">
                {/* <a
                  href="/Stats"
                  className="relative ml-8 inline-block bg-transparent border border-transparent rounded-md text-base font-medium text-gray-dark hover:bg-opacity-75"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-7 w-7"
                    viewBox="0 0 20 20"
                    fill="#FFFFFF"
                  >
                    <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                    <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                  </svg>{' '}
                </a> */}
                <MenuBar />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default AppHeader;
