import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-hot-toast';

import { useYopi } from '../contexts/yopi';
import * as Realm from 'realm-web';
function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { user, setUser, errors, setErrors } = useYopi();
  const { app, setApp } = useYopi();

  let navigate = useNavigate();

  useEffect(() => {
    async function init() {
      if (user) {
        navigate('/home');
      }
    }

    init();
  }, [user, navigate]);

  const loginAndRedirect = async () => {
    try {
      if (!user) {
        setUser(await app.logIn(Realm.Credentials.emailPassword(email, password)));
      }
    } catch (errors) {
      toast.error(errors.errors);
    }
  };

  return (
    <>
      <div className="h-screen bg-gray-black flex flex-col justify-center pt-8 px-8 sm:px-6 lg:px-8">
        <div className="flex mx-auto items-center">
          <a href="/">
            <img className="h-24 lg:h-24 w-auto" src="/yopilogo.png" alt="Home" />
          </a>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-yellow">
            Sign in to your machine
          </h2>
        </div>

        <div className="mt-4 lg:mx-auto mx-4 sm:w-full sm:max-w-md">
          <div className="bg-yellow lg:py-2 py-4 lg:px-4 px-12 shadow rounded-lg sm:px-10">
            <form
              onSubmit={e => {
                e.preventDefault();
                loginAndRedirect();
              }}
              className="space-y-6"
              action="#"
              method="POST"
            >
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700"></label>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Enter email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Enter your password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={async () => {
                    toast.promise(loginAndRedirect(), {
                      loading: 'Logging in...',
                      success: 'Successfully logged in!',
                      error: 'Incorrect email/password',
                    });

                    // catch (error) {
                    //   toast.error(error);
                    // }
                  }}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 bg-gray-black border
                  border-transparent rounded-md shadow-sm text-sm font-medium text-white-bright
                  bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2
                  focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
