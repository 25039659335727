/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import LatestFoodDelivery from './Cards/LatestFoodDelivery';
import NextFoodDelivery from './Cards/NextFoodDelivery';
import TotalFoodScheduled from './Cards/TotalFoodScheduled';
import DryFoodProgress from './Cards/DryFoodProgress';
import WetFoodProgress from './Cards/WetFoodProgress';
import WetFoodRemaining from './Cards/WetFoodRemaining';
import DeliveriesSummary from './Cards/DeliveriesSummary';

import Errors from './Errors';
import { useYopi } from '../contexts/yopi';

import Carousel from './Other/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import moment from 'moment-timezone';
import ScheduleTabs from './ScheduleTabs';

moment.tz.setDefault('Europe/Paris');

function getLatestDeliveryTime(timestamp) {
  const correctedTimestamp = moment(timestamp).add(-1, 'hour')
  return `${correctedTimestamp.format('HH:mm')} (${correctedTimestamp.fromNow()})`;
}

function getNextDeliveryTime(schedules) {
  console.log(schedules)
  const timestampsForNextDelivery = schedules
    .filter(
      schedule =>
        moment()
          .hours(parseInt(schedule.time.split(':')[0]))
          .minutes(parseInt(schedule.time.split(':')[1]))
          .isAfter(moment()),
    )
    .map(schedule => schedule.time)
    .sort();
  const timestampForNextDelivery = timestampsForNextDelivery[0];
  return timestampForNextDelivery;
}

function getDeliveredToday(schedules) {
  const deliveredToday = schedules.filter(schedule => schedule.delivered_today === true);
  return deliveredToday.length;
}

function ScheduledActivity({ deviceInfo }) {
  //upcoming delivery time is latest schedule where delivery=false
  const {
    config,
    telemetry,
    petName,
    aggData,
  } = useYopi();

  console.log('aggData', aggData)

  const INITIAL_VALUES = {
    upcomingDelivery: null,
    latestDelivery: '--',
    deliveredToday: 0,
    totalScheduledToday: 0,
  };

  const [activityValues, setActivityValues] = useState(INITIAL_VALUES);
  // const currentTime = new Date().getTime();

  useEffect(() => {
    if (telemetry && telemetry[telemetry.length - 1]) {
      const now = telemetry[telemetry.length - 1]; //this is the latest record
      if (now) {
        console.log('updating activity values !', config, now);
        setActivityValues({
          latestDelivery: getLatestDeliveryTime(now.timestamp),
          upcomingDelivery: getNextDeliveryTime(config.schedules),
          deliveredToday: getDeliveredToday(now.schedules),
          totalScheduledToday: config.schedules.length,
        });
      }
    }
   
  }, [telemetry, config]);

  return (
    <div
      className="items-center -md lg:w-full justify-between sm:ml-48
     sm:mr-48 lg:mx-0 shadow-xl border border-transparent lg:h-200"
     style={{ height: '73vh', overflowY: 'scroll', backgroundColor: 'black' }}
    >
      {/* Tabs */}
      <ScheduleTabs
      petName={petName}
        errorMsg={
          <div className="w-full lg:w-1/2  space-y-8 lg:mt-full">
            {telemetry[0]?.current_error_msg && (
              <Errors errorMsg={telemetry[0]?.current_error_msg} />
            )}
          </div>
        }
        dailyTabComponents={
          <div className="flex lg:flex-row flex-col ">
            <Carousel />
            <div className="w-full lg:w-1/2 space-y-4 pb-8 lg:mt-full">
              <DryFoodProgress
                completed={activityValues.deliveredToday}
                label={(aggData?.dry_food_eaten.toFixed(2) || '--') + ' g'}
              />
              <WetFoodProgress
                completed={aggData?.wet_food_progress.toFixed(2) || 0}
                label={(aggData?.wet_food_eaten.toFixed(2) || '--') + ' g'}
              />
              <TotalFoodScheduled
                foodAmount={
                  (config?.schedules.filter(schedule => schedule.active === true)?.length *
                    config?.wet_weight_by_dose +
                    config?.schedules.filter(schedule => schedule.active === false)?.length *
                      config?.dry_weight_by_dose || '--') + ' g'
                }
              />
              <DeliveriesSummary
                delivered={activityValues.deliveredToday}
                total={activityValues.totalScheduledToday}
              />
            </div>
          </div>
        }
        deviceTabComponents={
          <div className="w-full lg:w-1/2 space-y-4 pb-2 lg:mt-full">
            <LatestFoodDelivery deliveryTime={activityValues.latestDelivery || '--'} />
            <NextFoodDelivery
              deliveryTime={activityValues.upcomingDelivery}
            />
            <WetFoodRemaining
              foodAmount={telemetry[telemetry.length - 1]?.wet_food?.remaining_weight_g.toFixed(2)}
              capacity={telemetry[telemetry.length - 1]?.wet_food?.bag_capacity_g.toFixed(2)}
            />
          </div>
        }
      />
    </div>
  );
}

export default ScheduledActivity;
