import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Schedule from './pages/Schedule';
import Settings from './pages/Settings';
import Stats from './pages/Stats';

import { useYopi, YopiProvider } from './contexts/yopi';
import Account from './pages/Logout';
import { Toaster } from 'react-hot-toast';

const App = function () {
  return (
    <YopiProvider>
      <AppRouter />
      <Toaster />
    </YopiProvider>
  );
};

const AppRouter = () => {
  const { user } = useYopi();
  if (!user && window.location.pathname !== '/') window.location.replace('/')
  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="/account" element={<Account />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/stats" element={<Stats />} />
    </Routes>
  );
};

export default App;
