import { React, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useYopi } from '../../contexts/yopi';
import Logout from '../../pages/Logout';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
function PhoneMenu() {
  const { telemetry } = useYopi();

  return (
    <div className="lg:hidden flex mx-auto mr-16">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-left w-full rounded-md shadow-sm mr-4 -px-4 py-0 text-sm font-medium text-gray-dark hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-transparent focus:ring-transparent">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#FFFFFF"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <ChevronDownIcon className=" ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="origin-top-right absolute left-0 mt-2 p-4 rounded-md shadow-lg bg-gray-lightest ring-1 ring-gray ring-opacity-5 focus:outline-none"
            style={{ width: 200, zIndex: 9999 }}
          >
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="/Settings"
                    className={classNames(
                      active ? 'bg-gray text-gray-dark' : 'text-gray-darkest',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Settings
                  </a>
                )}
              </Menu.Item>
              <Logout></Logout>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default PhoneMenu;
