import React from 'react';

const ProgressBar = props => {
  const completed = props.completed;
  const label = props.label;
  const bgcolor = props.bgcolor || '#6a1b9a';

  const containerStyles = {
    height: 32,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 25,
    margin: 5,
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    minWidth: '3%',
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
  };

  const labelStyles = {
    marginLeft: 8,
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>
          {label} ({Math.round(completed)}%)
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
