import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useYopi } from '../../contexts/yopi';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { app } from '../../firebase';

const storage = getStorage(app);

function CarouselImage() {
  const { telemetry } = useYopi();

  const [images, setImages] = useState([]);

  const handleDownload = async imageUrl => {
    const url = await getDownloadURL(ref(storage, imageUrl));
    return url;
  };

  useEffect(() => {
    const fetchImages = async () => {
      const images = await Promise.allSettled(
        telemetry
          .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
          // .filter((_, index) => index < 10)
          .map(data => data.images)
          .flat()
          .map(async imageUrl => await handleDownload(imageUrl))
          .reverse(),
      );
      setImages(images.filter(el => el.status === 'fulfilled').map(el => el.value));
    };
    fetchImages();
  }, [telemetry]);

  return (
    <Carousel>
      {images.map((imageUrl, index) => (
        <div key={index}>
          <img
            alt="new cat"
            className="lg:w-full lg:h-full rounded-3xl lg:items-center p-4"
            src={imageUrl}
          />
        </div>
      ))}
    </Carousel>
  );
}

export default CarouselImage;
