/* eslint-disable jsx-a11y/anchor-is-valid */
import AppHeader from '../components/Layout/Header';
import Layout from '../components/Layout/Layout';
import ScheduledActivity from '../components/ScheduledActivity';
import { useYopi } from '../contexts/yopi';
import toast from 'react-hot-toast';
import moment from 'moment-timezone';

import iconWetFood from '../assets/wetFood.png';
import iconDryFood from '../assets/dryFood.png';
import iconSchedule from '../assets/schedule.png';
import iconPicture from '../assets/takePicture.png';

moment.tz.setDefault('Europe/Paris');
function Home() {
  const { config, syncConfigToDB, errors, setErrors } = useYopi();

  const update_dry_food = async () => {
    const newTimestamp = moment().toDate();
    try {
      syncConfigToDB({ push_dry_food: true, timestamp: newTimestamp });
    } catch (error) {
      throw error;
    }
  };
  const update_wet_food = async () => {
    const newTimestamp = moment().toDate();
    try {
      syncConfigToDB({ push_wet_food: true, timestamp: newTimestamp });
    } catch (error) {
      throw error;
    }
  };

  const take_picture = async () => {
    const newTimestamp = moment().toDate();
    try {
      syncConfigToDB({ take_picture: true, timestamp: newTimestamp });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Layout>
      <AppHeader />
      <ScheduledActivity />
      <div
        className=" fixed flex flex-row bottom-0 w-full bg-white-bright mt-2 p-2 space-y-4 bg-opacity-90"
        style={{ height: '12vh' }}
      >
        <div className="flex justify-center" style={{ flex: 1, margin: 0, marginTop: '-6vh' }}>
          <img
            className="img-footer"
            src={iconWetFood}
            alt="wet food"
            style={{ height: '100%' }}
            onClick={async () => {
              toast.promise(update_wet_food(), {
                loading: 'Pushing food...',
                success: 'Food delivery request sent!',
                error: 'Could not send request.',
              });
            }}
          />
        </div>
        <div className="flex justify-center" style={{ flex: 1, margin: 0, marginTop: '-6vh' }}>
          <img
            className="img-footer"
            src={iconDryFood}
            alt="dry food"
            style={{ height: '100%' }}
            onClick={async () => {
              toast.promise(update_dry_food(), {
                loading: 'Pushing food...',
                success: 'Food delivery request sent!',
                error: 'Could not send request.',
              });
            }}
          />
        </div>
        <div className="flex justify-center" style={{ flex: 1, margin: 0, marginTop: '-6vh' }}>
          <img
            className="img-footer"
            src={iconPicture}
            alt="take-pic"
            style={{ height: '100%' }}
            onClick={async () => {
              toast.promise(take_picture(), {
                loading: 'Taking picture...',
                success: 'Picture request sent!',
                error: 'Could not send request.',
              });
            }}
          />
        </div>
        <div className="flex justify-center" style={{ flex: 1, margin: 0, marginTop: '-6vh' }}>
          <a
            className="img-footer"
            href="/schedule"
            // className="inline-block w-full bg-yellow-accent py-2 px-4 border border-transparent rounded-md text-base text-center font-medium shadow-lg text-white hover:bg-opacity-75"
          >
            <img alt="schedule" src={iconSchedule} style={{ height: '100%' }} />
          </a>
        </div>

        {/* 

        <button
          onClick={async () => {
            toast.promise(update_dry_food(), {
              loading: 'Pushing food...',
              success: 'Food delivered!',
              error: 'Could not deliver food.',
            });
          }}
          className="inline-block bg-gray-darkest py-2 px-4 border border-transparent rounded-md text-base font-medium text-yellow-mineral hover:bg-opacity-50"
        >
          Deliver Dry Food
        </button>
        <button
          onClick={async () => {
            toast.promise(update_wet_food(), {
              loading: 'Pushing food...',
              success: 'Food delivered!',
              error: 'Could not deliver food.',
            });
          }}
          className="inline-block bg-gray-darkest py-2 px-4 border border-transparent rounded-md text-base font-medium text-yellow-mineral hover:bg-opacity-50"
        >
          Deliver Wet Food
        </button> */}
      </div>
    </Layout>
  );
}

export default Home;
