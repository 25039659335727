import { React, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useYopi } from '../../contexts/yopi';
import Logout from '../../pages/Logout';

function MenuBar() {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md  shadow-sm px-4 py-2 text-sm font-medium text-gray-dark hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-transparent focus:ring-transparent">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7"
            viewBox="0 0 20 20"
            fill="#FFFFFF"
          >
            <path
              fillRule="evenodd"
              d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
              clipRule="evenodd"
            />
          </svg>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className=" flex flex-row origin-top-right py-2 absolute right-0 mt-2 w-56 rounded-md 
        shadow-lg bg-gray-lightest ring-1 ring-gray ring-opacity-5 focus:outline-none"
          style={{ width: 200, zIndex: 9999 }}
        >
          <div className="w-full justify-center py-2 px-8 ">
            <Menu.Item
              className="bg-gray-lightest  text-gray-darkest text-sm font-bold flex justify-center rounded-lg shadow-lg
            focus:outline-none lg:py-2 py-4 lg:px-16 px-12 sm:px-8 focus:ring-2 focus:ring-offset-2 focus:ring-gray-light"
            >
              {({ active }) => (
                <a href="/Settings" className="text-md p-4 font-bold mt-4 ">
                  Settings
                </a>
              )}
            </Menu.Item>
            <Logout></Logout>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default MenuBar;
