import React from 'react';

function TotalFoodScheduled({ foodAmount }) {
  return (
    <div
      className="flex flex-col w-11/12 mx-auto lg:w-full 
         bg-gray bg-opacity-50 border-2 border-transparent shadow-lg lg:shadow-md  p-2 rounded-xl lg:rounded-md"
    >
      <div class="flex lg:flex-row lg:items-center lg:justify-between flex-col items-start justify-evenly ">
        <div>
          <span
            class="
               lg:text-sm
               text-xs
                font-semibold
                lg:tracking-wider
                uppercase
                inline-block
                py-1
                whitespace-normal
                sm:whitespace-nowrap
                rounded-md
                text-white-bright           
                "
          >
            ⏳ Total food scheduled today
          </span>
        </div>
        <div class="flex lg:text-right">
          <span class="lg:text-lg text-2xl whitespace-nowrap font-bold inline-block text-white-bright">
            {foodAmount}
          </span>
        </div>
      </div>
    </div>
  );
}
export default TotalFoodScheduled;
