import React, { useState, useEffect } from 'react';
import Modal from './Layout/Modal';
import { useYopi } from '../contexts/yopi';

import moment from 'moment-timezone';
moment().tz('Europe/Paris').format();

function TimePicker() {
  const fns = require('date-fns');
  const { user, client, schedules, setSchedules, syncConfigToDB } = useYopi();

  useEffect(() => {
    const getSchedules = async () => {
      const schedulesDb = await client
        .db('yopi_trial')
        .collection('config')
        .findOne({ serial_number: user.customData.serial_number });
      if (schedulesDb?.schedules) setSchedules(schedulesDb.schedules);
    };
    if (client && user) {
      getSchedules();
    }
  }, [client, user, setSchedules]);

  const [showModal, setShow] = useState(false);
  const [chosenTime, setChosenTime] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="flex items-center justify-center" style={{ height: '64px' }}>
        <button
          className=" bg-transparent shadow-md w-12 rounded-lg font-bold text-2xl mt-2  text-gray-dark relative "
          onClick={() => handleShow()}
        >
          {' '}
          +{' '}
        </button>
      </div>
      <Modal open={showModal} setOpen={setShow}>
        <div class="mt-2 p-5 w-56 content-center bg-gray-light rounded-lg shadow-xl">
          <div class="flex">
            <input
              onChange={event => setChosenTime(event.target.value)}
              type="time"
              id="appt"
              name="appt"
              required
            />
          </div>
        </div>
        <div className="flex flex-row justify-evenly mt-3">
          <button
            onClick={() => {
              const newTimestamp = moment().toDate();
              const newSchedules = [
                ...schedules,
                { active: true, time: chosenTime },
              ];
              setSchedules(newSchedules);
              setChosenTime(null);
              syncConfigToDB({ schedules: newSchedules, timestamp: newTimestamp });

              handleClose();
            }}
            className="bg-transparent text-gray-dark bg-yellow-bright p-2 rounded-md mt-4 font-bold inline-block"
          >
            {' '}
            Save
          </button>

          <button
            onClick={() => {
              handleClose();
            }}
            className="bg-transparent text-yellow-bright bg-gray-dark p-2 rounded-md mt-4 font-bold inline-block"
          >
            {' '}
            Close
          </button>
        </div>
      </Modal>
    </>
  );
}

export default TimePicker;
