import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

function ScheduleTabs({ petName, errorMsg, dailyTabComponents, deviceTabComponents }) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div>{errorMsg}</div>
      <Box sx={{ width: '100%', flex: 1, typography: 'body1' }}>
        <TabContext value={value}>
          <Box
            className="md bg-yellow-light font-bold "
            // sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: '#FFFF8F', borderRadius: 3 }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              orientation={'horizontal'}
            >
              <Tab label="Daily Needs &amp; Updates" value="1" style={{  fontSize:12, maxWidth: '33vw' }} />
              <Tab label="Device" value="2" style={{  fontSize:12, maxWidth: '33vw' }} />
              <Tab label="Physiologic Profile" value="3" style={{ fontSize:12, maxWidth: '33vw' }} />
            </TabList>
          </Box>

        
          <TabPanel
            value="1"
            className="align-middle text-lg text-white-cream"
            style={{ flex: 1, paddingBottom: 36 }}
          >
            <div>{dailyTabComponents}</div>
          </TabPanel>
          <TabPanel
            value="2"
            className="align-middle text-lg text-white-cream"
            style={{ flex: 1, paddingBottom: 36 }}
          >
            <div>{deviceTabComponents}</div>
          </TabPanel>
          <TabPanel
            value="3"
            className="align-middle text-lg text-center text-white-cream"
            style={{ flex: 1, paddingBottom: 36,  }}
          >
            Come back soon to discover the physiological profile of {petName || 'your cat'}!
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
export default ScheduleTabs;
